.formGroup {
  margin: 15px 0;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input {
  padding: 10px;
  border: 1px solid var(--color-grey-shade);
  border-radius: 3px;
}