.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  height: 72px;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;

  @media screen and (max-width: 991px) {
    /* */
    gap: 15px;
  }

  @media screen and (min-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.menuButton, .phoneButton {
  position: relative;
  font-size: 28px;
  line-height: 1em;
  padding: 10px;
  width: 48px;
  height: 48px;
  text-align: center;
  color: white;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.menuButton {
  &>svg {
    position: absolute;
    top: 12px;
    left: 12px;
    transform-origin: center;
    transition: transform 0.2s;
  }
}

.menuButtonOpen {
  &>svg {
    transform: rotate(-90deg);
  }
}

.mobileMenuOpen {
  
}

.mobileMenuClosed {

}

.text {
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
  }
}

.topBar {
  background-color: var(--color-red-10);
  height: 24px;
  font-size: 14px;  

  @media screen and (max-width: 992px) { 
    display: none;
  }
}

.topBar svg {
  height: 1em;
}

.logo{
  width: 100%;
}

.logoContainer {
  width: 218px;
}

.topBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  @media screen and (max-width: 767px) { 
    justify-content: space-between;
    padding: 0 15px;
  }
}

.navigationWrapper{
  background-color: transparent;
  transition: background-color 350ms;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.navigationWrapperOpen{
  background-color: var(--color-red);
  transition: background-color 350ms;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

@media screen and (max-width: 991px) {
  :global(.mobileMenuOpen) > .navigationWrapper {
    background-color: var(--color-red);
  }
}

.topBarButton{
  color: white;

  @media screen and (max-width: 767px) {
    & > span {
      display: none;
    }
  }
}

.topColorChange{
  background-color: var(--color-red-10);
  transition: background-color 350ms;
}

.topBarContainer a {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
  gap: 5px;
}

.navigation {
  position: relative;
  z-index: 2;
}


.alertDialogContent{
  border-radius: 5px;
}




/* Phone Number */

.contact-card {
  position: relative;
  max-width: 87rem;
  width: 100%;
  padding: 2rem;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  
  @media (min-width: 992px) {
    width: 57rem;
  }
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  cursor: pointer;
}

.close-icon {
  height: 1.5rem;
  width: 1.5rem;
  color: #6b7280;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
}

.contact-section,
.hours-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: initial;
  margin-bottom: 1rem;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contact-block,
.hours-block {
  margin-bottom: 1.5rem;
}

.block-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: initial;
  margin-bottom: 0.5rem;
}

.info-text {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #4b5563;
}

.phoneNumber{
  color: white;
  font-size: 25px;
  margin-top: 0;
  text-align: right;
}

.sales-opening-times{
  font-size: 11px;
  width: 200px;
  text-align: right;
  color: white;
}

.action-button {
  background-color: #dc2626;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.action-button:hover {
  background-color: #b91c1c;
}

.hours-table {
  width: 100%;
  border-collapse: collapse;
}

.hours-table th {
  text-align: left;
  padding: 0.5rem 0;
  font-weight: 600;
}

.hours-table td {
  padding: 0.5rem 0;
  border-top: 1px solid #e5e7eb;
}

.hours-table tr:first-child td {
  border-top: none;
}

.smartCodeModalContent {
  width: 440px;
  max-width: 100%;
  border-radius: 5px;
}

.smartCodeModalContentTop {
  padding: 30px 30px 0;

  &>h3 {
    margin-bottom: 30px;
  }
}

.smartCodeModalFooter {
  padding: 30px 30px 30px;
  background-color: var(--color-grey-light);
}

.smartCodeDescription {
  font-size: 12px;
}

.smartCodeInput {
  display: block;
  padding: 0.5em;
  border: 1px solid var(--color-grey);
  font-family: monospace;
  letter-spacing: 0.1em;
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: border-color 0.2s ease;
}

.smartCodeInput.empty {
  border-color: #ccc;
}

.smartCodeInput.valid {
  border-color: #4CAF50;
  color: #4CAF50;
}

.smartCodeInput.invalid {
  border-color: #f44336;
  color: #f44336;
}

.smartCodeSubmit {
  width: 100%;
  margin-bottom: 30px;
}

.alertDialogClose{
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  cursor: pointer;
}

.alertDialogCloseIcon{
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
}

.alertDialogClose:hover{
  background-color: transparent;
}
