.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.list {
  display: flex;
  gap: 10px;
  align-items: center;
}

.ellipsis, .button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 40px;

  @media screen and (min-width: 768px) {
    height: 48px;
    width: 48px;

  }
}

.ellipsis {
  margin-left: -10px;
  margin-right: -10px;
  color: var(--color-grey-tint);
}

.paginationPrevious, .paginationNext {
  .nextPreviousLabel {
    display: none;
  }
  &>svg {
    display: block;
  }

  @media (min-width: 768px) {
    .nextPreviousLabel {
      display: block;
    }
    &>svg {
      display: none;
    }
  }
}