.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 640px;
}

.backgroundImage {
  position: absolute;
  height: 100%;
  width: 66.6%;
}

.foregroundImage {
  position: absolute;
  top: 20%;
  width: 66.6%;
  height: 60%;
  border: 15px solid white;
}