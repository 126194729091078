.search{
    height: 100%;
    width: 100%;
    border: 1px solid black;
  }
  
  .mainSearch{
    width: 50%;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: row;

    @media screen and (max-width: 1044px) {
      width: 70%;
      flex-direction: column;
      align-items: start;
    }

    @media screen and (max-width: 578px) {
      width: 100%;
    }
  }
  
  .searchButton{
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 40px;
    gap: 10px;
  }
  
  .container {
    margin: auto;
  }
  
  
  
  
  
  .searchFilterItem {
    width: 100%;
    text-align: start;
    padding: 15px;
    gap: 10px;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    color: #37444d;
    border-top: 1px solid #dbfdff;
    font-size: 13px;
    font-family: var(--header-font);
    letter-spacing: 1px;
    font-weight: 600;
  }
  
  .searchFilterItem > p {
    font-weight: initial;
    text-transform: initial;
    font-size: 13px;
    font-family: var(--body-font);
  }
  
  .searchFilterItem > svg {
    font-size: 1em;
    min-width: 20px;
    margin-right: 10px;
    text-align: left;
    color: #f4081f;
  }
  
  .showMore {
    text-align: center;
    color: #007bff;
    padding: 10px;
    margin: 0;
  }

  .title{
    font-size: 34px;
  }
  
  .showMore:hover {
    text-decoration: underline;
  }