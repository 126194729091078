.columns {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.column {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: top;
  font-size: 16px;

  
  @media screen and (min-width: 1500px) {
    align-items: center;
  }

  & > svg {
    color: var(--color-red);
  }

  & > p {
    font-size: 15px;
  }
}