.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.wrapperTop {
  align-items: flex-start;
}

.column {
  flex: 1;
}

.column :global(.text-content) {
  margin-right: auto;
  margin-left: auto;
  transition: width 0.5s;

  @media screen and (min-width: 576px) {
      width:calc(576px / 2);
  }
  
  @media screen and (min-width: 768px) {
      width:calc((768px - 18px) / 2)
  }
  
  @media screen and (min-width: 992px) {
      width:calc((992px - 22px) / 2)
  }
  
  /* @media screen and (min-width: 1200px) {
      width:calc((1200px - 30px) / 2)
  }
  
  @media screen and (min-width: 1500px) {
      width:calc((1500px - 60px) / 2)
  } */
}

.columnLeft {
  position: relative;
}

.columnRight {
  position: relative;
}

.columnLeft :global(.text-content) {
  margin-right: 0;
}

.columnRight :global(.text-content) {
  margin-left: 0;
}

.columnLeft :global(.foreground-image) {
  right: 0;
}

.columnRight :global(.background-image) {
  right: 0;
}

.pageSection {
  margin-top: 60px;
}