.ships {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  justify-content: center;
}

.ship {
  position: relative;
  padding-top: 100%;
  display: block;
}

.name {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  color: white;
  z-index: 1;
  font-size: 24px;
  font-family: var(--font-header);
  letter-spacing: 0.05em;
  line-height: 1em;
  text-transform: uppercase;
}

.darkGradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(0deg, #000 5%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 0.65;
  mix-blend-mode: hard-light;
}


/* Loading Shimmer Effect*/

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.grid-item {
  background-color: #f0f0f0;
  height: 200px;
  width: 100%;
}

.shimmer {
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #e0e0e0 50%,
    #f0f0f0 100%
  );
  background-size: 400% 400%;
  animation: shimmer 2s ease infinite;
}

@keyframes shimmer {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}