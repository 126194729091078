/* AlertDialog.css */

.alert-dialog-content {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.alert-dialog-content-without-backdrop {
  border: none;
  height: 720px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.alert-dialog-content::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.alert-dialog-header {
  margin-bottom: 1rem;
}

.alert-dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
}

.alert-dialog-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.alert-dialog-description {
  color: #666;
  line-height: 1.5;
}

.alert-dialog-action {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: var(--color-red);
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.alert-dialog-action:hover {
  background-color: var(--color-red);
  opacity: 0.8;
}

.alert-dialog-cancel {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: white;
  color: #333;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.alert-dialog-cancel:hover {
  background-color: #f3f4f6;
}

/* Button variants */
.button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.button-default {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.button-default:hover {
  background-color: #2563eb;
}

.button-outline {
  background-color: white;
  color: #333;
  border: 1px solid #e5e7eb;
}

.button-outline:hover {
  background-color: #f3f4f6;
}

.button-destructive {
  background-color: #ef4444;
  color: white;
  border: none;
}

.button-destructive:hover {
  background-color: #dc2626;
}

/* Focus states */
.button:focus,
.alert-dialog-action:focus,
.alert-dialog-cancel:focus {
  outline: 2px solid var(--color-red);
  outline-offset: 2px;
}

/* Animation for dialog */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.alert-dialog-content[open] {
  animation: fadeIn 0.1s ease-out;
}

.alert-dialog-content-without-backdrop[open] {
  animation: fadeIn 0.1s ease-out;
}