.container {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  font-size: 64px;
}

.from {
  font-family: var(--header-font);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1em;
  font-size: 0.27em;
}

.price {
  font-size: 64px;
  line-height: 0.75em;
  color: var(--color-red);
  font-family: var(--header-font);
  font-weight: 900;
}

.PP {
  font-family: var(--header-font);
  align-self: flex-end;
  letter-spacing: 0.05em;
  line-height: 1em;
  font-size: 0.27em;
}