.modal {
  position: fixed;
  padding: 15px;
  border-radius: 5px;
  border: none;
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  opacity: 0;
  transform-origin: center;
  
  &>h4 {
    padding-right: 45px;
    width: 500px;
  }

  &[open] {
    opacity: 1;
    animation: slideToCenter 0.3s ease-out;
  }

  &.closing {
    animation: slideFromCenter 0.3s ease-in;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;
  }

  &.closing::backdrop {
    animation: fadeOut 0.3s ease-in forwards;
  }
}

@keyframes slideToCenter {
  from {
    transform: translate(var(--start-x), var(--start-y)) scale(0);
    opacity: 0;
  }
  to {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes slideFromCenter {
  from {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(var(--start-x), var(--start-y)) scale(0);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Rest of your existing styles */
.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 28px;
  padding: 15px;
  background: none;
  border: none;
  color: inherit;
}

.table {
  width: 100%;
  border-collapse: collapse;
  
  &>thead>tr>th, &>tbody>tr>td {
    text-align: left;
    border-bottom: 1px solid var(--color-grey-light);
    padding: 10px;
    font-size: 14px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &>thead>tr>th {
    border-bottom: 1px solid var(--color-grey);
    padding-bottom: 2px;
  }
}