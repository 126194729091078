/* Base styles */
.searchBar {
  display: grid;
  background-color: #FFF;
  border-radius: 5px;
  height: 68px;
  grid-template-columns: 80% 20%;
  gap: 0;
  width: 100%;
  max-width: 940px;
  margin: auto;
  box-shadow: 0 0 15px rgba(104, 167, 212, 0.15);
  color: var(--color-marina);
  user-select: none;
}

.dropdownExit {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.09);
  z-index: 999;
}

.dropdownExitOpen {
  display: block;
}

.options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  background-color: #FFF;
  border-radius: 5px 0 0 5px;
}

.options > :first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
}


.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #FFF;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 15px 15px rgba(73, 114, 143, 0.25);
}

.dropdownLinesShips {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px 20px;
}

.dropdownDestination {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 20px 20px;
}

.dropdownDropdownListOptions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px 20px;
}

.listOption {
  text-align: left;
  padding: 15px 10px;
  background: transparent;
  border: 0;
  border-radius: 3px;
  line-height: 1em;
  font-size: 15px;
}

.listOption:hover {
  background-color: var(--color-grey-light);
}

.listOption:focus {
  outline: 2px solid var(--color-red-10);
}

.listOptionSelected {
  color: #FFF;
  background-color: var(--color-red-10);
  font-weight: 700;
}

.listOptionSelected:hover {
  color: #FFF;
  background-color: var(--color-red-10);
  cursor: default;
}

.optionButton {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.searchButton {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: var(--color-red-10);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #FFF;
}

.option {
  position: relative;
}

/* Option button styles */
.optionButton {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}

.optionLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.optionLabel i {
  font-size: 24px;
  color: var(--color-red-10);
}

.optionText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.optionLabelText {
  font-size: 12px;
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--color-grey-medium);
}

.optionLabelValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  font-size: 18px;
  font-family: var(--font-family-content);
  margin-top: 0;
}

.optionChevron {
  display: flex;
  height: 100%;
  align-items: flex-end;
  font-size: 20px;
  margin-bottom: 7px;
  transform-origin: 10px 36px;
  transition: transform 0.15s;
}

/* Open states */
.searchBarOpen {
  .options {
    border-bottom-left-radius: 0;
  }
}

.searchBarOpen .optionButton {
  opacity: 0.5;
  background-color: #f0f0f0;
}

.searchBarOpen .optionButton i {
  color: var(--color-marina);
}

.searchBarOpen .options {
  z-index: 1000;
}

.searchBarOpen .dropdown {
  display: block;
}

.searchBarOpen .dropdown[opened-option="passengers"] {
  width: 100%;
  right: 0;
}

@media screen and (min-width: 768px) {
  .searchBarOpen .dropdown[opened-option="passengers"] {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .searchBarOpen .dropdown[opened-option="passengers"] {
    width: 25%;
  }
}

.optionOpen {
  composes: option;
}

.optionOpen .optionButton,
.optionOpen .optionButton:hover {
  opacity: 1;
  background-color: #FFF;
}

.optionOpen .optionIcon {
  color: var(--color-red-10);
}

.optionOpen .optionChevron {
  transform: rotate(180deg);
}

.option::after {
  position: absolute;
  top: 15px;
  bottom: 15px;
  right: 0;
  width: 1px;
  content: '';
  background-color: var(--color-grey-tint);
}

.option:nth-child(3)::after {
  display: none;
}

/* Extras section */
.extras {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  max-width: 940px;
  margin: auto;
  margin-top: 10px;
}

.extrasButton {
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: #FFF;
  box-shadow: 0 0 15px rgba(104, 167, 212, 0.15);
  border-radius: 5px;
  color: var(--color-red-10);
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1em;
  height: 38px;
  border: 0;
  gap: 8px;
}

.extrasButton:hover {
  background-color: #fafafa;
}

.extrasSection {
  display: flex;
  flex-direction: row;
  gap: 0;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  cursor: text;
}

.extrasSectionValid input,
.extrasSectionValid input:focus {
  border: 1px solid var(--color-red-10);
  border-right: 0;
}

.smartCode {
  height: 38px;
  padding: 0 10px;
  font-family: var(--font-family-monospace);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 5px 0 0 5px;
  border: 1px solid var(--color-grey-tint);
  border-right: 0;
  outline: 0;
  letter-spacing: 0;
  transition: letter-spacing 0.25s;
}

.smartCodeHasContent {
  letter-spacing: 2px;
}

.smartCodeButton {
  height: 38px;
  padding: 0 15px;
  background-color: var(--color-red-10);
  box-shadow: 0 0 15px rgba(104, 167, 212, 0.15);
  border-radius: 0 5px 5px 0;
  color: #FFF;
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 1em;
}

.smartCodeButton:disabled {
  border: 1px solid var(--color-grey-tint);
  border-left: 0;
  background-color: var(--color-grey-light);
  color: var(--color-grey-medium);
  pointer-events: none;
}

/* Advanced search region */
.advancedRegion {
  position: relative;
}

.advancedRegionOpen .advancedSearch {
  display: block;
}

/* Calendar and date styles - Add these based on your calendar component needs */

.dropdownDate {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px 20px 20px;

  @media screen and (max-width: 922px) {
    padding: 10px 0 20px;
  }
}

.dropdownDateToggleHeader {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  @media screen and (max-width: 767px) {
    grid-template-columns: 0.25fr 2fr 0.25fr
  }
}

.toggleSwitch {
  display: flex;
  border: 1px solid var(--color-grey-tint);
  border-radius: 3px;
  overflow: hidden;
}

.toggleButton {
  flex: 1;
  padding: 10px 15px;
  border: 0;
  background: transparent;
  font-size: 14px;
  color: var(--color-grey-medium);
  transition: all 0.2s ease;
}

.toggleButtonActive {
  background-color: var(--color-red-10);
  color: #FFF;
}

.calendarHeader {
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.dateSelectors {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.monthSelect,
.yearSelect {
  /* Existing styles */
  padding: 10px;
  border: 1px solid var(--color-grey-tint);
  border-radius: 3px;
  font-size: 14px;
  color: var(--color-marina);
  background-color: #FFF;
  
  /* Add these styles to fix the dropdown arrow */
  appearance: none; /* Removes default arrow */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  padding-right: 30px; /* Make room for custom arrow */
}

/* Optionally, if you want to change the arrow color to match your design */
.monthSelect,
.yearSelect {
  /* Alternative using a simpler arrow */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23495057' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-size: 16px;
}

.monthSelect {
  min-width: 140px;
}

.yearSelect {
  min-width: 100px;
}

.yearChangeButtons {
  margin-bottom: -50px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.yearChange {
  background-color: transparent;
  width: 32px;
  height: 32px;
  color: var(--color-red-10);
  border: 0;
  border-radius: 3px;
  transition: background-color 0.2s ease;
}

.yearChange:hover:not(:disabled) {
  background-color: var(--color-grey-light);
}

.yearChange:disabled {
  background-color: var(--color-grey-light);
  color: var(--color-grey-tint);
}

.years {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media screen and (max-width: 922px) {
    grid-template-columns: 1fr;
    gap: 60px;
  }

  @media screen and (max-width: 768px) {
    gap: 30px;
  }
}

.year {
  position: relative;
}

.year:first-child::after {
  position: absolute;
  top: 15px;
  bottom: 0;
  right: -15px;
  width: 1px;
  content: '';
  background-color: var(--color-grey-tint);

  @media screen and (max-width: 922px) {
    bottom: -30px;
    top: auto;
    left: 15px;
    right: 15px;
    width: auto;
    height: 1px;
  }

  @media screen and (max-width: 768px) {
    bottom: -15px;
  }
}

.year h3 {
  margin: 0;
  margin-bottom: 8px;
  justify-content: center;
  text-align: center;
  height: 32px;
  margin-top: -12px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-family: var(--font-family-content);
  font-weight: 600;
  color: var(--color-grey-medium);
}

.months {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
}

.month {
  border: 0;
  border-radius: 5px;
  background: transparent;
  text-align: center;
  padding: 15px;
  border-radius: 3px;
  line-height: 1em;
  font-size: 15px;
  transition: all 0.2s ease;
}

.month:hover:not(:disabled) {
  background-color: var(--color-grey-light);
  
  @media screen and (max-width: 922px) {
    background-color: var(--color-red-10);
  }
}

.month:disabled {
  background-color: var(--color-grey-light);
  color: var(--color-grey-tint);
  cursor: not-allowed;
}

.monthSelected {
  color: #FFF;
  background-color: var(--color-red-10);
  font-weight: 700;
}

.monthSelected:hover {
  color: #FFF;
  background-color: var(--color-red-10);
  cursor: default;
}

.advancedSearchInputGroups {
  margin-top: 20px;
}

.advancedSearchInputGroup {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.duration,
.flexibility,
.advancedSearchStartingPort,
.advancedSearchVisitingPort {
  width: 50%;
}

.duration select,
.flexibility select,
.advancedSearchStartingPort select,
.advancedSearchVisitingPort select,
.advancedSearchShips select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-grey-tint);
  border-radius: 3px;
  font-size: 14px;
  color: var(--color-marina);
  background-color: #FFF;
}

.dropdownLinesShipsShips {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
}


.calendarHeader h3 {
  margin: 0;
  justify-content: center;
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-family: var(--font-family-content);
  font-weight: 600;
  color: var(--color-grey-medium);
}

/* We can reuse your existing yearChange class for the navigation buttons */

.weekDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  margin-bottom: 10px;
}

.weekDay {
  text-align: center;
  font-size: 12px;
  font-family: var(--font-family-header);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--color-grey-medium);
  padding: 5px;
}

.calendarDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendarDay {
  position: relative;
  border: 0;
  border-radius: 3px;
  background: transparent;
  text-align: center;
  padding: 15px;
  line-height: 1em;
  font-size: 15px;
  transition: all 0.2s ease;

  
  @media screen and (max-width: 922px) {
    padding: 12px;
  }
}

.flexibleDay {
  outline: 1px solid rgba(244, 8, 31, 0.33);
  background: rgba(244, 8, 31, 0.05);
}

.flexibilityIndicator {
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 12px;
  color: var(--color-red-10);
  line-height: 1;
  font-family: var(--font-family-monospace);
}

/* Reusing month hover styles */
.calendarDay:hover:not(:disabled):not(.emptyDay) {
  background-color: var(--color-grey-light);
}

/* Reusing month disabled styles */
.calendarDay:disabled,
.emptyDay {
  background-color: var(--color-grey-light);
  color: var(--color-grey-tint);
  cursor: not-allowed;
}

/* Reusing monthSelected styles */
.selectedDay {
  color: #FFF;
  background-color: var(--color-red-10);
  font-weight: 700;
}

.selectedDay:hover {
  color: #FFF;
  background-color: var(--color-red-10) !important;
  cursor: default;
}



.mobileClose {
  display: none;
}

.advancedRegionMobileClosed {
  z-index: 10;
  @media (max-width: 992px) {
    .searchBar {
      display: block;
      border-radius: 5px;
      overflow: hidden;
      

      .options {
        display: none;
      }
    }
  }
}

.advancedRegionMobileOpen {
  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: white;

    
  
    .mobileClose {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 15px;
      z-index: 10000;
      font-size: 24px;
      line-height: 1em;
    }

    .innerWrapper {
      padding: 30px;
      width: 480px;
      max-width: 100%;
    }

    .extras {
      display: none;
    }

    .searchBar {
      display: block;
      height: auto;

      &.searchBarOpen {
        z-index: 101;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;

        &+.mobileClose {
          display: none;
        }

        .option {
          display: none;
        }

        .optionOpen {
          display: block;
        }

        .options {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        
        .dropdown {
          position: initial;
          top: auto;
          width: 100%;
          flex-grow: 1;
          overflow-y: auto;
        }

        .searchButton {
          display: none;
        }

        .dropdownDropdownListOptions {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 270px);
          overflow-y: auto;
        }
      }

      .options {
        grid-template-columns: unset;
      }

      .option::after {
        bottom: 0;
        left: 15px;
        top: unset;
        right: 15px;
        height: 1px;
        width: auto;
      }

      .searchButton {
        border-radius: 5px;
        height: 64px;
      }
    }
  }
}