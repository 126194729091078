.wrapper {
    padding: 56.25% 0 0 0;
    position: relative;
}

.iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .5);
}