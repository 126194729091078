.modal {
  border: none;
  padding: 0;
  margin: auto;
  width: 900px;
  height: 700px;
  max-width: 100vw;
  max-height: 100vh;
  background: white;
  position: fixed;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
}

.modal::backdrop {
  background: rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px) {
  .modal {
    height: 600px;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background,
.backgroundMobile {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;
}

.background,
.foreground {
  display: none;
}

.backgroundMobile,
.foregroundMobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .background,
  .foreground {
    display: block;
  }

  .backgroundMobile,
  .foregroundMobile {
    display: none;
  }
}

.foreground,
.foregroundMobile {
  max-width: calc(100% - 60px);
  margin-top: 60px;
  user-select: none;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 440px;
  max-width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .formContainer {
    left: 50%;
  }
}

.formContainer form {
  padding: 30px;
}

@media screen and (min-width: 480px) {
  .formContainer form {
    padding: 30px 60px;
  }
}

.input {
  font-size: 18px;
  width: 100%;
  height: 48px;
  margin-bottom: 10px;
  border: 0;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #003366;
}

@media screen and (min-width: 992px) {
  .input {
    margin-bottom: 20px;
    height: 64px;
  }
}

.submit {
  background-color: #e60012;
  width: 100%;
  height: 64px;
  color: white;
  font-family: inherit;
  letter-spacing: 1.2px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  border-radius: 5px;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 rgba(181, 1, 10, 0);
  border: 1px solid white;
}

.submit:hover {
  box-shadow: 0 0 14px rgb(135, 0, 7);
  border: 1px solid #e60012;
}

.close {
  font-size: 16px;
  height: 32px;
  border-radius: 5px;
  line-height: 32px;
  display: block;
  text-align: center;
  cursor: pointer;
  background-color: white;
  color: #e60012;
  padding: 0 10px;
  width: 100%;
  border: none;
}

.centered {
  left: 0;
  right: 0;
  margin: auto;
  width: 440px;
  max-width: 100%;
}

@media screen and (min-width: 992px) {
  .centered {
    left: 20%;
    right: 20%;
  }
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}