/* Loading Shimmer Effect*/

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 20px;
  }
  
  .grid-item {
    background-color: #f0f0f0;
    height: 280px;
    width: 100%;
  }
  
  .shimmer {
    background-image: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 200%;
    animation: shimmer 2s ease infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  .terms{
    background-color: #f8fcff;
    padding: 30px;
    border-radius: 15px;
  }

  .terms > p{
    font-size: 15px;
  }