.groupWrapper {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media (max-width: 1259px) {
  .groupWrapper {
    grid-template-columns: repeat(2, minmax(400px, 1fr));
  }
  
  /* If the last item is the only one in its row, make it full width */
  .groupWrapper > *:last-child:nth-child(2n + 1) {
    grid-column: 1 / -1;
  }
}

@media (max-width: 829px) {
  .groupWrapper {
    grid-template-columns: 1fr;
  }
}

.group {
  /* border: 1px solid var(--color-red); */
  border-radius: 5px;
  overflow: hidden;
}

.groupHeader {
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 200px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  & > h3 {
    position: relative;
    font-size: 22px;
    font-family: var(--font-header);
    color: white;
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 15px;
    z-index: 2;
    margin-bottom: 0;
  }
}

.darkGradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(0deg, #000 5%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 0.65;
  mix-blend-mode: hard-light;
  z-index: 1;
}

.list {
  padding: 5px 0;
  border-left: 1px solid var(--color-grey);
  border-right: 1px solid var(--color-grey);
}

.listItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 10px 15px;
  align-items: center;
  transition: opacity 0.2s;
  min-height: 74px;

  &:hover {
    opacity: 0.8;
  }

  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid var(--color-grey-light);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:last-child:after {
    display: none;
  }
}

.details {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 1.2em;
  font-family: var(--font-body);
}

.date {
  font-size: 0.75em;
}

.groupLink {
  & > a {
    display: block;
    width: 100%;
    margin-top: 5px;
    line-height: 28px;
    height: 48px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}