.articleView {
    display: flex;
    flex-direction: row;
    gap: 50px;

    @media screen and (max-width: 1490px) {
        flex-direction: column;
    }
}

.relatedArticles {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 1490px) {
        width: 100%;
    }
}

.relatedArticles h3 {
    font-weight: 400;
    color: var(--color-maroon);
}

.articleContent {
    width: 80%;

    @media screen and (max-width: 1490px) {
        width: 100%;
    }

    &>ul {
        list-style: initial;
        margin-left: 20px;

        & > li {
            margin-bottom: 0.6em;
        }
    }

    &>h3 {
        &>img{
            margin-right: 10px;
        }
    }
}

.title {
    position: absolute;
    top: 110px;
    color: white;
    font-weight: 500;
    font-size: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 1044px) {
        font-size: 25px;
        white-space: initial;
    }
}