.categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media screen and (max-width: 1125px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 987px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 755px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.articles {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.category {
    display: flex;
    height: 220px;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #f4081f;
    padding: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    outline: 4px solid #fff;

    @media screen and (max-width: 755px) {
        height: 150px;
    }
}

.article {
    display: flex;
    height: 100px;
    width: 310px;
    border-radius: 5px;
    border: 1px solid #f4081f;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    outline: 4px solid #fff;
}

.category_icon {
    width: 20px;
    height: 20px;
    position: relative;
}

.category_icon img {
    filter: brightness(0) saturate(100%) invert(32%) sepia(95%) saturate(4237%) hue-rotate(348deg) brightness(91%) contrast(107%);
}

.category h4 {

    text-align: center;
    font-weight: 500;
    color: #303030;

    @media screen and (max-width: 755px) {
        font-size: 15px;
    }
}

.article h5 {
    text-align: center;
    font-weight: 500;
    color: #000000;
}

.category:hover {
    outline: 1.2px solid #f4081f;
}