.slide {
  position: relative;
  /* min-width: 350px; */
  height: 350px;
  width: 100%;
}

.background {
  position: relative;
  padding-top: 96px;
  padding-bottom: 96px;
}