.searchableInputContainer {
  position: relative;
  min-width: 10%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.searchableInput {
  border: none;
  padding: 9px 12px;
  font-size: 16px;
  font-family: var(--body-font);
  width: 100%;
  height: 100%;
  background-color: inherit;
}

.searchableInputMain{
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchableInputMainHasIcon {
  position: relative;

  &>svg {
    position: absolute;
    left: 12px;
    top: 12px;
    user-select: none;
    pointer-events: none;
  }

  .searchableInput {
    padding-left: 40px;
  }
}

.searchableInput:focus{
  outline: none;
}

.searchableInput::placeholder{
  color: rgb(22, 22, 20);
  font-weight: 5800;
  font-size: 15px;
}

.searchableDropdown {
  position: absolute;
  top: 92%;
  left: -1px;
  right: 1px;
  width: 100.3%;
  max-height: 400px;
  overflow-y: auto;
  background-color: inherit;
  border: inherit;
  z-index: 10;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top: none;
  box-shadow: inherit;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.searchableDropdownItem {
  cursor: pointer;
}

.searchableDropdownItem:hover {
  background-color: #f0f0f0;
}