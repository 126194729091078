.expand {
  position: absolute;
  top: -0.125em;
  right: -0.125em;
  background-color: white;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  color: var(--color-red);
  font-size: 1em;
  font-weight: 600;
}

.clamp {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: block;
  margin: 0;
}

.wrapper {
  position: relative;
  min-height: calc(1em + 5px);
  max-width: 100%;
  display: block;
  width: 100%;
}