.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  min-height: 450px;
  padding-top: 30px;
  border-radius: 10px;
  overflow: hidden;
}

.header {
  & > span {
    background: var(--color-star-buy-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    line-height: 1em;
  }

  & > svg {
    color: var(--color-star-buy-solid);
  }
}

.containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 15px;
  z-index: 1;
  background: white;
}

.headerDetails {
  flex-grow: 1;

  &>h3 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 3px;
    line-height: 1em;
    color: var(--color-maroon);
  }
}

.details {
  display: flex;
  gap: 20px;
  font-size: 12px;
  line-height: 1em;
}

.detail {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--color-grey-dark);

  &>svg {
    font-size: 1.2em;
  }
}

.itinerary {
  font-size: 16px;
  line-height: 1em;
  color: var(--color-maroon);
  margin-bottom: 8px;
}

.lineLogo {
  position: relative;
  width: 124px;
  height: 48px;

  &>div>img {
    object-fit: contain !important;
  }
}

.tsAndCs {
  margin: auto;
  margin-top: 15px;
  font-size: 12px;
  padding: 0 30px;
  text-align: center;
  max-width: 1200px;
}

.containerFooter {

}

.promotions {
  display: flex;
  font-size: 16px;
  gap: 15px;
  position: relative;
  z-index: 1;
  padding: 15px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
  position: relative;
  z-index: 1;
}

.includedSection {
  display: flex;
  gap: 30px;
  padding: 15px;
}

.includedLabel {
  padding: 0;
  color: white;
  font-family: var(--font-header);
  letter-spacing: 0.05em;
  font-weight: 500;
  font-size: 24px;
}

.includeds {
  display: flex;
  gap: 30px;
  padding: 0;
  color: white;
}

.include {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
}

.gradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-star-buy-gradient);
  opacity: 0;
  mix-blend-mode: hard-light;
}

.darkGradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(0deg, #000 5%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 0.55;
  mix-blend-mode: hard-light;
}

.buttonContainer {
  flex-grow: 1;
  max-width: 520px;
}

.viewButton {
  display: flex;
  align-items: center;
  justify-content: center !important;
  height: 64px;
  width: 100%;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}



/* Loading Animation */

.shimmer {
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #e0e0e0 50%,
    #f0f0f0 100%
  );
  background-size: 400% 400%;
  animation: shimmer 2s ease infinite;
}

@keyframes shimmer {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}