.posts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;


  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.post {
  position: relative;
  min-height: 200px;
  transition: opacity 0.2s;
  border-radius: 5px;
  overflow: hidden;

  &:hover {
    opacity: 0.8;
  }
}

.darkGradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(0deg, #000 5%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 0.65;
  mix-blend-mode: hard-light;
}

.header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  margin-bottom: 0;
  color: white;
  font-size: 18px;
  z-index: 2;
}