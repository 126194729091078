.topSection {
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
  gap: 30px;
  
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.topImage {
  position: relative;
  flex-grow: 1;
  min-width: calc(55% + 15px);
  position: relative;
  @media screen and (max-width: 767px) {
    height: 500px;
  }

  @media screen and (min-width: 768px) {
    border-right: 30px solid white;
  }
}

.topSectionFlipped {
  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }

  .topImage {
    border-right: 0;
    border-left: 0;
    @media screen and (min-width: 768px) {
      border-left: 30px solid white;
    }
  }
}

.text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5em;
}

.textSection {    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: calc(33.33% - 30px);
  @media screen and (min-width: 768px) {
    padding: 60px 0 200px;
    min-height: 700px;
  }
}

.bottomSection {
  display: flex;
  height: 160px;
  align-items: stretch;
  flex-direction: row-reverse;
  @media screen and (min-width: 768px) {
    height: 300px;
  }
}

.bottomSideImage {
  position: relative;
  flex-grow: 1;
  min-width: calc(66.66% + 150px);
  margin-right: -150px;
  margin-top: -150px;
  margin-bottom: 100px;
  border-left: 15px solid white;
  border-top: 15px solid white;

  
  @media screen and (min-width: 768px) {
    border-left: 30px solid white;
    border-top: 30px solid white;
    margin-bottom: 150px;
  }
}

.bottomImage {
  position: relative;
  flex-grow: 1;
  min-width: calc(33.33%);
  margin-left: -15px;
  margin-right: -60px;
  border-top: 15px solid white;
  border-right: 15px solid white;

  @media screen and (min-width: 768px) {
    margin-left: -150px;
    border-top: 30px solid white;
    border-right: 0;
    margin-right: 0;
  }
}

.bottomSectionFlipped {
  flex-direction: row;

  .bottomSideImage {
    margin-left: -150px;
    margin-right: 0;
    border-left: 0;
    border-right: 15px solid white;
    @media screen and (min-width: 768px) {
      border-right: 30px solid white;
    }
  }
    
  .bottomImage {
    margin-right: -60px;
    margin-left: -15px;
    border-left: 15px solid white;
    
    @media screen and (min-width: 768px) {
      margin-right: -150px;
      margin-left: 0;
      border-left: 0;
    }
  }
}