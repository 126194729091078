.categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media screen and (max-width: 1500px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 987px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 755px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.category {
    background-color: #fff;
    padding: 20px 30px;
    height: 280px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
}

.titleLink{
    text-decoration: initial;
    display: flex;
    grid-gap: 4px;
    gap: 4px;
    color: rgb(0,0,0);
    text-transform: uppercase;
    font-size: 15.4px;
    font-weight: 800;
    height: fit-content;
    border: 0;
    line-height: 1.2;
    letter-spacing: 1px;
}

.title{
    display: flex;
    align-items: start;
    gap: 8px;
    font-weight: 400;
}

.titleLink:hover{
    text-decoration: underline;
}

.articles{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    gap: 10px;
}

.article{
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 12.4px;
    font-weight: 800;
    color: var(--color-red);
    padding-left: 28.8px;
}

.articleTitleLink{
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.2;
    color: var(--color-red);
}

.articleTitleLink:hover{
    text-decoration-thickness: 2px;
}

.title svg{
   color: var(--color-red);
}