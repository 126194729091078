.imageWrapper {
  position: relative;
  padding-top: 50%;
}

:global(.page-section) + .imageWrapper {
  margin-top: 30px;
}

.termsAndConditions {
  font-size: 12px;
  line-height: 13px;
}

.termsAndConditionsTitle {
  font-size: 12px;
  line-height: 13px;
}