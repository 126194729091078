.viewport {
  overflow: hidden;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  user-select: none;
}

.slide {
  position: relative;
  height: 200px;
  max-width: 355px;
  flex: 0 0 385px;
  padding: 0 15px;
}

.innerWrapper {
  position: relative;
  flex: 0 0 385px;
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
}