.articles {
  display: flex;
  flex-direction: row;
  gap: 28px;

  @media screen and (max-width: 1195px) {
    flex-direction: column;
  }
}

.articleGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 755px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
}

.article {
  display: flex;
  height: 100px;
  width: 330px;
  border-radius: 5px;
  border: 1px solid #f4081f;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  outline: 4px solid #fff;

  @media screen and (max-width: 1493px) {
    width: 300px;
  }

  @media screen and (max-width: 1313px) {
    width: 270px;
  }

  @media screen and (max-width: 1195px) {
    width: 450px;
  }

  @media screen and (max-width: 991px) {
    width: 350px;
  }

  @media screen and (max-width: 755px) {
    width: 100%;
  }
}

.article h5 {
  text-align: center;
  font-weight: 500;
  color: #000000;
}

.article:hover {
  outline: 1.2px solid #f4081f;
}
