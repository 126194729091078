.select {
  border-radius: 4px;
  border: 1px solid var(--color-grey-medium);
  padding: 0.5em 0.75em;
  padding-right: 2.5em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--color-grey-deep);
  text-transform: camelcase;

  &::-ms-expand {
    display: none;
  }
}

.wrapper {
  display: inline-block;
  position: relative;

  &:after {
    border: 2.5px solid transparent;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.625em;
    margin-top: -0.4375em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 0.625em;
    right: 1.125em;
    z-index: 4;
    
    border-color: var(--color-grey-deep);
  }
}