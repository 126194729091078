.item {
  border-bottom: 1px solid var(--color-grey-tint);
}

.itemToggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 15px;
}

.itemToggleIcon {
  color: var(--color-red);
  transition: transform 0.3s ease;
}

.iconRotate {
  transform: rotate(180deg);
}

.answerWrapper {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.answer {
  padding: 15px 15px 45px;
}