.navigation {
  height: 100%;
  .navigationInnerWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 100%;
  }
}

.fixedNavigation {
  width: 100%;
  height: 100px;
  position: fixed;
  background-color: var(--color-red);
  top: -200px;
  transition: top 0.5s ease-in-out;
  left: 0;
  z-index: 100;
  gap: 10px;
}

.fixedNavigationShow {
  top: 0;
}

.itemNavigation{
  position: relative;
  z-index: 2;
  margin-bottom: -49px;
  padding-top: 5px;
}

.topBar {
  background-color: var(--color-red-10);
  height: 24px;
  font-size: 14px;
}

.topBar svg {
  height: 1em;
}

.topBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.topBarContainer a {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
  gap: 5px;
}

.dropdownButton {
  padding: 20px;
  cursor: pointer;
  height: 100%;
}

.dropdownButtonSelected {
  background-color: white;

  .dropdownLabel {
    color: var(--color-maroon);
    font-weight: 500;
  }
}

.dropdownButtonLink{
  margin-top: 15px;
}

.dropdownLabel {
  font-size: 15px;
  color: white;
  font-weight: 200;
  font-family: var(--header-font);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: left;
  justify-content: left;

  & > svg {
    display: none;
  }

  & > span {
    display: flex;
    gap: 15px;
  }
}

.dropdownIcon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  color: var(--color-red);

  & > div > img {
    object-fit: contain !important;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.dropdownLinks{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dropDownFeaturedLabel{
  position: absolute;
  z-index: 10;
  background-color: var(--color-red-10);
  display: flex;
  padding: 2px 8px 2px 8px;
  border-radius: 2px;
  gap: 8px;
  align-items: center;
  top: 10px;
  flex-direction: row;
  color: white;
  font-size: 14px;
  font-weight: 200;
  font-family: var(--body-font);
  text-align: left;
  justify-content: left;
}

.labelSelected{
  font-weight: 500;
}

.dropdownLink{
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.dropdownContainer {
  @media screen and (min-width: 992px) {
    width: 100vw;
    position: absolute;
    top: 100%;
    left: 50%; 
    transform: translateX(-50%) translateY(-0.5rem);
    z-index: 100;
    transition-property: max-height, transform, opacity;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    transform-origin: top;
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    pointer-events: none;
  }
}

.innerContainer {
  background-color: white;
  @media screen and (min-width: 992px) {
    box-shadow: 10px 10px 10px -2px rgba(0, 0, 0, 0.05);
    padding: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.dropdownContainer.open {
  opacity: 1;
  height: fit-content;
  max-height: 800px;
  transform: translateX(-50%) translateY(0);
  pointer-events: all;
}
.dropdownHeader {
  font-weight: 800;
  margin-bottom: 30px;
}

.dropdownHeader>span {
  font-weight: normal;
}

.dropDownGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.dropDownGridItem {
  width: 100%;
  height: 100px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* .dropDownGridItem:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
} */

.navigation {
  @media screen and (max-width: 991px) {
    position: fixed;
    height: calc(100vh - 72px);
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: transform 0.2s ease-in;
    background-color: white;
    overflow-y: auto;

    .navigationInnerWrapper {
      display: flex;
      flex-direction: column;
      gap: 0;

      & > div {
        border-top: 1px solid var(--color-grey);
  
        &:last-child {
          flex-grow: 1;
        }
      }
  
      .dropdownButton {
        height: 80px;
        width: 100%;
        padding: 30px;
      }
  
      .dropdownLabel {
        display: flex;
        flex-direction: row;
        color: var(--color-maroon);
        align-items: center;
        justify-content: space-between;
  
        & > svg {
          display: block;
        }
      }
  
      .dropdownContainer {
        max-height: 0;
        overflow: hidden;
        transition: none; /*max-height 0.2s ease-out;*/
        transform: none;
      }
  
      .innerContainer {
        padding: 15px;
        margin-left: 15px;
        border-left: 1px solid var(--color-grey);
        padding-left: 30px;
        padding-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .dropDownGrid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

:global(.mobileMenuOpen) .navigation {
  transform: translateX(0);

  @media screen and (max-width: 991px) {
    .dropdownContainer.open {
      max-height: 3000px;
      transform: none;
    }
  }
}

.navigationMobileLinks {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-top: 15px;
  background-color: #fafafa;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.navigationMobileLink {
  display: flex;
  padding: 16px 30px;
  text-align: left;
  gap: 15px;
  align-items: center;
  font-family: var(--header-font);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 15px;
}