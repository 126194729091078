.main {
  width: 100%;
  height: fit-content;
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent linear-gradient(180deg, #ABABAB 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 1;
  top: 0;
  left: 0;
}

.overlayShort {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent linear-gradient(180deg, #5c5b5b 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 1;
  top: 0;
  left: 0;
}


.title {
  color: white;
  font-weight: 400;
  margin-top: 10px;
  font-family: var(--header-font);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: left;
  justify-content: left;
  margin-left: -4px;

  @media screen and (max-width: 1044px) {
    font-size: 25px;
    white-space: initial;
  }
}

.statIconStyle {
  filter: invert(1) brightness(100);
}

.stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
}

.label {
  font-size: 14px;
  color: white;
  font-weight: 500;
  font-family: var(--header-font);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: left;
  justify-content: left;
}

.statItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}

.statImage {
  position: relative;
  width: 14px;
  height: 14px;
}

.subtitle {
  font-size: 18px;
  color: white;
  font-weight: 500;
  margin-top: 10px;
  font-family: var(--header-font);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: left;
  justify-content: left;
}

.headerScrollButtonHolder {
  width: 100%;
  height: 80px;
  margin-top: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.statsVisible {
  margin-top: 110px;
}

.headerScrollButton {
  margin-top: 12px;
  cursor: pointer;
  transition: 500ms;
}

.headerScrollButtonTitle {
  font-size: 15px;
  color: white;
  font-weight: 200;
  font-family: var(--header-font);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-align: left;
  justify-content: left;
}

.headerScrollButton:hover {
  transform: translateY(5px);
}

.imageWrapper {
  width: 100%;
  height: 500px;
  position: relative;
}

.imageWrapperShort {
  width: 100%;
  height: 200px;
  position: relative;
}

.search {
  height: 100%;
  width: 100%;
}

.mainSearch {
  width: 50%;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 1144px) {
    width: 70%;
    flex-direction: column;
    align-items: start;
  }

  @media screen and (max-width: 578px) {
    width: 100%;
  }
}

.searchButton {
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 40px;
  gap: 10px;
}

.container {
  position: absolute;
  top: 32%;
  margin: auto;
  left: 0;
  right: 0;
}





.searchFilterItem {
  width: 100%;
  text-align: start;
  padding: 15px;
  gap: 10px;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  color: #37444d;
  border-top: 1px solid #dbfdff;
  font-size: 13px;
  font-family: var(--header-font);
  letter-spacing: 1px;
  font-weight: 600;
}

.searchFilterItem>p {
  font-weight: initial;
  text-transform: initial;
  font-size: 13px;
  font-family: var(--body-font);
}

.searchFilterItem>svg {
  font-size: 1em;
  min-width: 20px;
  margin-right: 10px;
  text-align: left;
  color: #f4081f;
}

/* &:first-child .searchFilterItem {
  border-top: none;
} */