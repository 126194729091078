.slide {
  position: relative;
}

.imageContainer {
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  top: 0;
}

.textContainer {
  margin-left: 50%;
  padding: 60px;
}

.link {
  display: block;
  margin-top: 30px;
  border: 1px solid var(--color-red);
  color: var(--color-red);
  padding: 14px;
  width: 200px;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--header-font);
  letter-spacing: 1px;
  border-radius: 3px;
  font-weight: 500;
}