.viewport {
  overflow: hidden;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.slide {
  position: relative;
  height: 800px;
  max-width: 100vw;
  flex: 0 0 100%; /* This ensures each slide takes full width */
}

.foreground {
  object-fit: contain !important;
  padding-top: 96px;
  z-index: 2;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent linear-gradient(180deg, #ABABAB 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 1;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.outterContainer {
  position: relative;
}

.searchBarContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 96px;
}