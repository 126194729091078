.circle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  z-index: 1;
  width: 120px;
  height: 120px;
  background-color: var(--color-red-10);
  border-radius: 50%;
  transform: rotate(5deg);
  margin: 30px 30px 30px;
}

.bookNow {
  color: white;
  text-transform: uppercase;
  font-family: var(--font-header);
  letter-spacing: 0.05em;
  font-size: 12px;
  margin-top: -1em;
  font-weight: 600;
}

.priceWrapper {
  display: flex;
  gap: 2px;
  color: white;
  font-size: 28px;
}

.from, .pp {
  line-height: 0.75em;
  text-transform: uppercase;
  font-family: var(--font-header);
  letter-spacing: 0.05em;
  font-size: 10px;
  padding-bottom: 0.15em;
  padding-top: 0.1em;
}

.pp {
  align-self: flex-end;
}

.price {
  font-size: 32px;
  line-height: 0.75em;
  font-family: var(--header-font);
  font-weight: 900;
}